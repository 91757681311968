import React from "react";
import Helmet from "react-helmet";

import Layout from "@components/Layout";
import Container from "@components/Container";
import News from "@components/News";

const IndexPage = () => {
  return (
    <Layout pageName="home">
      <Helmet>
        <title>SLS & Advogados Associados</title>
      </Helmet>
      <div className="news">
        <News />
      </div>
    </Layout>
  );
};

export default IndexPage;
